import Vue from "vue";
import App from "./App.vue";
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import vuetify from "@/plugins/vuetify"; // path to vuetify export

Vue.config.productionTip = false;

Vue.use(Toast, {
  timeout: 5000, position: "bottom-right", maxToasts: 10, newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
})

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
