import axios from "axios";
import settings from "../settings";

const apiClient = axios.create({
  baseURL: settings.baseUrlApi + "/signatures",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  create(gegevens) {
    // console.log(gegevens);
    gegevens.id = settings.key;

    return apiClient.post("/create", gegevens);
  },
  upload(excelFile, gegevens) {
    // Add id key to gegevens
    gegevens.id = settings.key;
    // Prepare and add gegevens to formData
    var formData = new FormData();
    formData.append("id", settings.key);
    formData.append("Logo1", gegevens.logo1);
    formData.append("Logo1Alt", gegevens.logo1Alt);
    formData.append("Logo1Url", gegevens.logo1Url);
    formData.append("Logo2", gegevens.logo2);
    formData.append("Logo2Alt", gegevens.logo2Alt);
    formData.append("Logo2Url", gegevens.logo2Url);
    formData.append("FontFamily", gegevens.fontFamily);
    formData.append("FontSize", gegevens.fontSize);
    formData.append("Taal", gegevens.taal);
    formData.append("EmailSubject", gegevens.emailSubject);
    formData.append("SignatureToUse", gegevens.signatureToUse);
    formData.append("MailFrom", gegevens.mailFrom);
    if (gegevens.socialMediaContent.inGebruik) {
      formData.append(
        "SocialMediaInGebruik",
        gegevens.socialMediaContent.inGebruik
      );
      formData.append("SocialMediaTekst", gegevens.socialMediaContent.tekst);

      if (gegevens.socialMediaContent.images.length == 5) {
        formData.append(
          "SocialMedia1Alt",
          gegevens.socialMediaContent.images[0].alt
        );
        formData.append(
          "SocialMedia1Url",
          gegevens.socialMediaContent.images[0].url
        );
        formData.append(
          "SocialMedia1Img",
          gegevens.socialMediaContent.images[0].img
        );
        formData.append(
          "SocialMedia1InGebruik",
          gegevens.socialMediaContent.images[0].inGebruik
        );

        formData.append(
          "SocialMedia2Alt",
          gegevens.socialMediaContent.images[1].alt
        );
        formData.append(
          "SocialMedia2Url",
          gegevens.socialMediaContent.images[1].url
        );
        formData.append(
          "SocialMedia2Img",
          gegevens.socialMediaContent.images[1].img
        );
        formData.append(
          "SocialMedia2InGebruik",
          gegevens.socialMediaContent.images[1].inGebruik
        );

        formData.append(
          "SocialMedia3Alt",
          gegevens.socialMediaContent.images[2].alt
        );
        formData.append(
          "SocialMedia3Url",
          gegevens.socialMediaContent.images[2].url
        );
        formData.append(
          "SocialMedia3Img",
          gegevens.socialMediaContent.images[2].img
        );
        formData.append(
          "SocialMedia3InGebruik",
          gegevens.socialMediaContent.images[2].inGebruik
        );

        formData.append(
          "SocialMedia4Alt",
          gegevens.socialMediaContent.images[3].alt
        );
        formData.append(
          "SocialMedia4Url",
          gegevens.socialMediaContent.images[3].url
        );
        formData.append(
          "SocialMedia4Img",
          gegevens.socialMediaContent.images[3].img
        );
        formData.append(
          "SocialMedia4InGebruik",
          gegevens.socialMediaContent.images[3].inGebruik
        );

        formData.append(
          "SocialMedia5Alt",
          gegevens.socialMediaContent.images[4].alt
        );
        formData.append(
          "SocialMedia5Url",
          gegevens.socialMediaContent.images[4].url
        );
        formData.append(
          "SocialMedia5Img",
          gegevens.socialMediaContent.images[4].img
        );
        formData.append(
          "SocialMedia5InGebruik",
          gegevens.socialMediaContent.images[4].inGebruik
        );
      }
    }
    if (gegevens.banner.inGebruik) {
      formData.append("BannerAlt", gegevens.banner.alt);
      formData.append("BannerUrl", gegevens.banner.url);
      formData.append("BannerImg", gegevens.banner.img);
      formData.append("BannerInGebruik", gegevens.banner.inGebruik);
    }

    formData.append("emailSignatureFile", excelFile);
    // const obj = { gegevens: gegevens, emailSignatureFile: excelFile };
    // const json = JSON.stringify(obj);
    // const blob = new Blob([json], { type: "application/json" });
    // // console.log(blob);
    // formData.append("uploadExcel", blob);
    // Send request
    return apiClient.post("/upload", formData, {
      responseType: "blob",
    });
  },
};
