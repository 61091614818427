<template>
  <div>
    <v-row no-gutters class="pa-0 mb-2 bg-theme-5">
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-btn
          :class="tabSignature ? 'bg-theme-1 theme-5' : 'theme-1'"
          width="100%"
          :text="!tabSignature"
          elevation="0"
          tile
          @click="chooseCreate"
        >
          Create
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          :class="tabExcelUpload ? 'bg-theme-1 theme-5' : 'theme-1'"
          width="100%"
          :text="!tabExcelUpload"
          elevation="0"
          tile
          @click="chooseUpload"
        >
          Upload
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <Signature v-if="tabSignature" />
    <ExcelUploadView v-if="tabExcelUpload" />
  </div>
</template>

<script>
import Signature from "./Signature.vue";
import ExcelUploadView from "./ExcelUploadView.vue";

export default {
  name: "Home",
  components: {
    Signature,
    ExcelUploadView,
  },
  data() {
    return {
      tabSignature: true,
      tabExcelUpload: false,
    };
  },
  methods: {
    chooseCreate() {
      this.tabSignature = true;
      this.tabExcelUpload = false;
    },
    chooseUpload() {
      this.tabSignature = false;
      this.tabExcelUpload = true;
    },
  },
};
</script>

<style>
.ma-0 {
  margin: 0px;
}
.ma-1 {
  margin: 4px;
}
.ma-2 {
  margin: 8px;
}
.ma-3 {
  margin: 12px;
}
.ma-4 {
  margin: 16px;
}
.ma-5 {
  margin: 20px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.pa-0 {
  padding: 0px;
}
.pa-1 {
  padding: 4px;
}
.pa-2 {
  padding: 8px;
}
.pa-3 {
  padding: 12px;
}
.pa-4 {
  padding: 16px;
}
.pa-5 {
  padding: 20px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pb-5 {
  padding-bottom: 20px;
}

/* other classes */
.transparent {
  background-color: transparent !important;
}
/* Color Theme text */
.theme-1 {
  color: #f0d290 !important;
}
.theme-2 {
  color: #de834d !important;
}
.theme-3 {
  color: #a3423c !important;
}
.theme-4 {
  color: #a5aaa3 !important;
}
.theme-5 {
  color: #781d42 !important;
}
/* Color Theme bg */
.bg-theme-1 {
  background-color: #f0d290 !important;
}
.bg-theme-2 {
  background-color: #de834d !important;
}
.bg-theme-3 {
  background-color: #a3423c !important;
}
.bg-theme-4 {
  background-color: #a5aaa3 !important;
}
.bg-theme-5 {
  background-color: #781d42 !important;
}
</style>
