<template>
  <v-container>
    <!-- floating button, but make sure there's enough space at 
        the bottom for it not to hide anything -->
    <v-btn
      class="bg-theme-5 theme-1"
      @click="submit"
      fab
      bottom
      right
      fixed
      :loading="loading"
    >
      send
      <!-- <v-icon>mdi-content-save</v-icon> -->
    </v-btn>
    <h1>Upload</h1>
    <v-form ref="signatureForm">
      <!-- Mail to send to -->
      <v-row>
        <v-col> <h2>Excel File</h2></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          Excel file upload coming here
          <!-- <input type="file" id="myFile" name="filename" /> -->

          <v-file-input
            label="Upload file"
            v-model="excelFile"
            hide-details="auto"
            truncate-length="70"
          ></v-file-input>
        </v-col>
      </v-row>
      <!-- Settings -->
      <v-row>
        <v-col> <h2>Settings</h2></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-select
            hide-details="auto"
            v-model="gegevens.taal"
            :items="talen"
            label="taal"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-select
            hide-details="auto"
            v-model="gegevens.signatureToUse"
            :items="signaturesToUse"
            label="Signature to use"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-select
            hide-details="auto"
            v-model="gegevens.mailFrom"
            :items="mailFrom"
            label="mail from"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.fontFamily"
            label="font-family"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.fontSize"
            label="font-size"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" xl="3">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.emailSubject"
            label="email subject"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Logo 1 -->
      <v-row>
        <v-col> <h2>Logo 1</h2></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo1"
            label="logo bovenaan"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo1Alt"
            label="logo bovenaan alt"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo1Url"
            label="logo bovenaan url"
            hint="starten met 'https://' of 'http://' "
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Logo 2 -->
      <v-row>
        <v-col> <h2>Logo 2</h2></v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo2"
            label="logo onderaan"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo2Alt"
            label="logo onderaan alt"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            hide-details="auto"
            v-model="gegevens.logo2Url"
            label="logo onderaan url"
            hint="starten met 'https://' of 'http://' "
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Banner-->
      <v-row>
        <v-col cols="12">
          <v-card class="bg-theme-1">
            <v-card-title>
              <v-col cols="1">
                <v-checkbox v-model="gegevens.banner.inGebruik"></v-checkbox>
              </v-col>
              <v-col cols="10">
                <h2>Banner</h2>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  @click="gegevens.banner.inGebruik = true"
                  v-if="!gegevens.banner.inGebruik"
                >
                  <v-icon> mdi-arrow-down-drop-circle </v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
            <v-card-text v-if="gegevens.banner.inGebruik">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    hide-details="auto"
                    v-model="gegevens.banner.img"
                    label="banner onderaan"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="gegevens.banner.alt"
                    label="banner onderaan alt"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="gegevens.banner.url"
                    label="banner onderaan url"
                    hint="starten met 'https://' of 'http://' "
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Social media content-->
      <v-row>
        <v-col cols="12">
          <v-card class="bg-theme-1">
            <v-card-title>
              <v-col cols="1">
                <v-checkbox
                  v-model="gegevens.socialMediaContent.inGebruik"
                ></v-checkbox>
              </v-col>
              <v-col cols="10">
                <h2>Social Media Content</h2>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  @click="gegevens.socialMediaContent.inGebruik = true"
                  v-if="!gegevens.socialMediaContent.inGebruik"
                >
                  <v-icon> mdi-arrow-down-drop-circle </v-icon>
                </v-btn>
              </v-col>
            </v-card-title>
            <v-card-text v-if="gegevens.socialMediaContent.inGebruik">
              <v-row>
                <v-col>
                  <v-text-field
                    hide-details="auto"
                    v-model="gegevens.socialMediaContent.tekst"
                    label="tekst"
                    hint="vb: 'Hello'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-for="socialMedia in gegevens.socialMediaContent.images"
                :key="socialMedia.id"
              >
                <v-col cols="1" sm="1" md="1" xl="1">
                  <v-checkbox
                    v-model="socialMedia.inGebruik"
                    hide-details="auto"
                  ></v-checkbox>
                </v-col>
                <v-col cols="11" sm="3" md="2" xl="2">
                  <v-text-field
                    :disabled="!socialMedia.inGebruik"
                    hide-details="auto"
                    v-model="socialMedia.alt"
                    label="alt"
                    hint="vb: 'Facebook'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="4" xl="4">
                  <v-text-field
                    :disabled="!socialMedia.inGebruik"
                    hide-details="auto"
                    v-model="socialMedia.url"
                    label="url"
                    hint="vb: 'https://letmegooglethat.com/'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" xl="5">
                  <v-text-field
                    :disabled="!socialMedia.inGebruik"
                    hide-details="auto"
                    v-model="socialMedia.img"
                    label="img"
                    hint="vb: 'https://pim.ipcomdigital.eu/logo-isopartner.png'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <v-card height="60px" class="transparent" flat>
          <v-btn class="ma-2 bg-theme-2 theme-5" @click="fillInLogos">
            Fill logos
          </v-btn>
          <v-btn class="ma-2 bg-theme-2 theme-5" @click="fillInLogosForIpcom">
            Fill logos for IPCOM
          </v-btn>
          <v-btn class="ma-2 bg-theme-2 theme-5" @click="fillInLogosForCH">
            Fill logos for CH
          </v-btn>
          <v-btn class="ma-2 bg-theme-2 theme-5" @click="emptyData">
            Empty data
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignatureService from "@/services/SignatureService.js";

export default {
  name: "ExcelUploadView",
  components: {},
  data() {
    return {
      excelFile: null,
      loading: false,
      // talen: ["Engels", "Zwitsers", "Nederlands"],
      talen: [
        { text: "Engels", value: 1 },
        { text: "Nederlands", value: 3 },
        { text: "Zwitsers", value: 2 },
        { text: "Spaans", value: 4 },
      ],
      signaturesToUse: [
        { text: "ISOPARTNER", value: 1 },
        { text: "IPCOM", value: 2 },
      ],
      mailFrom: [
        { text: "Marketing", value: 1 },
        { text: "Annelies", value: 2 },
      ],
      gegevens: {
        taal: 1,
        signatureToUse: 1,
        mailFrom: 1,
        fontFamily: "Helvetica, Arial, sans-serif",
        fontSize: "12",
        emailSubject: "Your signature",
        logo1: "",
        logo1Alt: "",
        logo1Url: "",
        logo2: "",
        logo2Alt: "",
        logo2Url: "",
        banner: {
          inGebruik: true,
          img: "",
          alt: "",
          url: "",
        },
        socialMediaContent: {
          inGebruik: true,
          tekst: "Följ oss på sociala medier",
          images: [
            {
              id: 1,
              alt: "facebook",
              url: "https://www.facebook.com/IsopartnerAB/",
              img: "https://marketing.ipcom.be/office/signature/Facebook.png",
              inGebruik: true,
            },
            {
              id: 2,
              alt: "linkedIn",
              url: "https://www.linkedin.com/company/profisolab/",
              img: "https://marketing.ipcom.be/office/signature/LinkedIn.png",
              inGebruik: true,
            },
            {
              id: 3,
              alt: "Instagram",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Instagram.png",
              inGebruik: false,
            },
            {
              id: 4,
              alt: "Twitter",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Twitter.png",
              inGebruik: false,
            },
            {
              id: 5,
              alt: "Xing",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Xing.png",
              inGebruik: false,
            },
          ],
        },
      },
    };
  },
  created() {
    this.fillInLogos();
  },
  methods: {
    submit() {
      // console.log(this.gegevens);
      this.loading = true;
      SignatureService.upload(this.excelFile, this.gegevens)
        .then((resp) => {
          // console.log(resp);
          var fileName = resp.headers["filename"];
          var objectUrl = window.URL.createObjectURL(
            new File([resp.data], fileName, {
              type: resp.headers["content-type"],
            })
          );
          var link = document.createElement("a");
          link.href = objectUrl;
          link.download = fileName;
          link.click();
          this.$toast.success("Mails were sent.");
        })
        .catch(() => {
          // (error) => {
          // console.log(error);
          this.$toast.error(
            "There was an error. Please check everything and try again. If this continues, please check the logs."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fillInLogos() {
      this.gegevens.signatureToUse = 1;

      this.gegevens.logo1 =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=logo-isopartner.png";
      this.gegevens.logo1Alt = "ISOPARTNER";
      this.gegevens.logo1Url = "https://www.isopartner.com";
      this.gegevens.logo2 =
        "https://marketing.ipcom.be/office/signature/IpcomGroupCompanyMovingForward.png";
      this.gegevens.logo2Alt = "MOVING FORWARD TOGETHER";
      this.gegevens.logo2Url = "https://www.ipcom.be/group-companies";
      this.gegevens.banner.inGebruik = true;
      this.gegevens.banner.img =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=neuen_Webshop.jpg";
      this.gegevens.banner.url = "https://shop.isopartner.ch";
      this.gegevens.banner.alt = "Banner";
    },
    fillInLogosForIpcom() {
      this.gegevens.signatureToUse = 2;

      this.gegevens.logo1 =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=Logo_IPCOM.png";
      this.gegevens.logo1Alt = "IPCOM";
      this.gegevens.logo1Url = "https://www.ipcom.be";
      this.gegevens.logo2 =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=MovingForward_IPCOM.jpg";
      this.gegevens.logo2Alt = "MOVING FORWARD TOGETHER";
      this.gegevens.logo2Url = "https://www.ipcom.be/group-companies";
      this.gegevens.socialMediaContent.inGebruik = false;
      this.gegevens.banner.inGebruik = true;
      this.gegevens.banner.img =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=neuen_Webshop.jpg";
      this.gegevens.banner.url = "https://shop.isopartner.ch";
      this.gegevens.banner.alt = "Banner";
    },
    fillInLogosForCH() {
      this.gegevens.signatureToUse = 1;
      this.gegevens.mailFrom = 2;
      this.gegevens.taal = 2;
      this.gegevens.emailSubject = "Ihre neue ISOPARTNER E-Mail-Signatur";

      this.gegevens.logo1 =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=logo-isopartner-e-mailsignature.png";
      this.gegevens.logo1Alt = "ISOPARTNER CH";
      this.gegevens.logo1Url = "https://www.isopartner.ch";
      this.gegevens.logo2 =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=MovingForward_IPCOM.jpg";
      this.gegevens.logo2Alt = "MOVING FORWARD TOGETHER";
      this.gegevens.logo2Url = "https://www.ipcom.be/group-companies";
      this.gegevens.socialMediaContent.inGebruik = false;
      this.gegevens.banner.inGebruik = true;
      this.gegevens.banner.img =
        "https://pim.ipcomdigital.eu/Attachments/DownloadAttachmentByName?id=5da905b7-933b-4757-a37b-b9608397708a&type=product&name=neuen_Webshop.jpg";
      this.gegevens.banner.url = "https://shop.isopartner.ch";
      this.gegevens.banner.alt = "Banner";
    },
    emptyData() {
      this.gegevens = {
        taal: 1,
        signatureToUse: 1,
        mailFrom: 1,
        fontFamily: "Helvetica, Arial, sans-serif",
        fontSize: "12",
        emailSubject: "Your signature",
        logo1: "",
        logo1Alt: "",
        logo1Url: "",
        logo2: "",
        logo2Alt: "",
        logo2Url: "",
        banner: {
          inGebruik: true,
          img: "",
          alt: "",
          url: "",
        },
        socialMediaContent: {
          inGebruik: true,
          tekst: "",
          images: [
            {
              id: 1,
              alt: "facebook",
              url: "https://www.facebook.com/IsopartnerAB/",
              img: "https://marketing.ipcom.be/office/signature/Facebook.png",
              inGebruik: true,
            },
            {
              id: 2,
              alt: "linkedIn",
              url: "https://www.linkedin.com/company/profisolab/",
              img: "https://marketing.ipcom.be/office/signature/LinkedIn.png",
              inGebruik: true,
            },
            {
              id: 3,
              alt: "Instagram",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Instagram.png",
              inGebruik: false,
            },
            {
              id: 4,
              alt: "Twitter",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Twitter.png",
              inGebruik: false,
            },
            {
              id: 5,
              alt: "Xing",
              url: "https://www.google.com",
              img: "https://marketing.ipcom.be/office/signature/Xing.png",
              inGebruik: false,
            },
          ],
        },
      };
    },
  },
};
</script>
