<template>
  <div id="app" data-app>
    <Home />
  </div>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<style>
body {
  background-color: #fbffe2;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #a3423c;
  /* margin-top: 60px; */
}
</style>
